<template>
	<el-dialog title="执行排班计划" :visible.sync="doPlanDialog" width="60%">
		<div>
			<el-form ref="myForm" :model="myForm" label-width="100px" :show-message="false">
				<el-form-item label="线路" prop="routeId" required>
					<el-select v-model="myForm.routeId" filterable>
						<el-option
							v-for="item in exitRouteList"
							:key="item.routeId"
							:value="item.routeId"
							:label="item.routeName"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="排班日期" prop="time" required>
					<el-date-picker
						v-model="myForm.time"
						type="daterange"
						value-format="yyyy-MM-dd"
						range-separator="-"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						:picker-options="pickerOptions()"
					>
					</el-date-picker>
				</el-form-item>
			</el-form>
		</div>
		<div slot="footer">
			<el-button @click="doPlanDialog = false">取消</el-button>
			<el-button type="primary" @click="sureHandle">执行</el-button>
		</div>
	</el-dialog>
</template>

<script>
import dayjs from "dayjs";

export default {
	data() {
		return {
			myForm: {
				routeId: "",
				time: []
				/* pickerOptions: {
					disabledDate(time) {
						return dayjs().getTime() > dayjs(time).getTime;
					}
				} */
			}
		};
	},

	props: {
		value: Boolean,
		exitRouteList: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		doPlanDialog: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			}
		}
	},

	methods: {
		pickerOptions() {
			return {
				disabledDate(time) {
					return dayjs() > dayjs(time);
					// return dayjs("2024-08-01") > dayjs(time);
				}
			};
		},
		sureHandle() {
			if (!this.myForm.routeId) {
				this.$message.warning("请选择线路");
				return;
			}
			if (!this.myForm.time.length) {
				this.$message.warning("请选择日期");
				return;
			}
			const [startDate, endDate] = this.myForm.time;
			const params = {
				...this.myForm,
				startDate,
				endDate
			};
			delete params.time;
			let url = "/crrc-palm-bus/intelligentScheduling/executePlan";
			this.$http.post(url, params, { baseType: "base" }).then(() => {
				this.doPlanDialog = false;
				this.$message.success("执行成功");
			});
		}
	}
};
</script>
