<template>
	<el-dialog title="生成排班计划" :visible.sync="addPlanDialog" width="60%">
		<div>
			<el-form ref="myForm" :model="myForm" label-width="100px" :show-message="false">
				<el-form-item label="线路" prop="routeId" required>
					<el-select v-model="myForm.routeId" filterable>
						<el-option v-for="item in routeList" :key="item.routeId" :value="item.routeId" :label="item.cName"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="排班依据" prop="schedulingType" required>
					<el-radio-group v-model="myForm.schedulingType">
						<el-radio label="1">依照客流数据生成排班计划</el-radio>
						<el-radio label="2">依照线路基础数据</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
		</div>
		<div slot="footer">
			<el-button @click="addPlanDialog = false">取消</el-button>
			<el-button type="primary" @click="sureHandle">生成</el-button>
		</div>
	</el-dialog>
</template>

<script>
export default {
	data() {
		return {
			myForm: {
				routeId: "",
				schedulingType: "1"
			}
		};
	},

	props: {
		value: Boolean,
		routeList: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		addPlanDialog: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			}
		}
	},

	methods: {
		sureHandle() {
			if (!this.myForm.routeId) {
				this.$message.warning("请选择线路");
				return;
			}
			const params = {
				...this.myForm
			};
			let url = "/crrc-palm-bus/intelligentScheduling/generatePlan";
			this.$http.post(url, params, { baseType: "base" }).then(() => {
				this.addPlanDialog = false;
				this.$message.success("生成成功");
				this.$emit("reload");
			});
		}
	}
};
</script>
