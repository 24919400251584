<template>
	<div id="plannedShift" class="smart-sh">
		<jy-query ref="form" :model="formData">
			<jy-query-item prop="routeId" label="线路">
				<el-select v-model="formData.routeId">
					<el-option v-for="one in routeList" :key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item prop="vehicleNo" label="车辆">
				<el-input v-model="formData.vehicleNo" placeholder="请输入"></el-input>
			</jy-query-item>
			<jy-query-item prop="driverNa" label="司机">
				<el-input v-model="formData.driverNa" placeholder="请输入"></el-input>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck" v-if="btnexist('scheduleVehicleSchedulSmartSchedulList')">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')" v-if="btnexist('scheduleVehicleSchedulSmartSchedulReset')">
					重置
				</el-button>
				<!-- <el-row>
				</el-row> -->
			</template>
			<template slot="operate">
				<el-button @click="delAllHandle" v-if="btnexist('scheduleVehicleSchedulSmartSchedulDelete')">删除排班计划</el-button>
				<el-button type="primary" @click="addPlanHandle" v-if="btnexist('scheduleVehicleSchedulSmartSchedulAdd')"
					>生成排班计划</el-button
				>
				<el-button type="primary" @click="doPlanHandle" v-if="btnexist('scheduleVehicleSchedulSmartSchedulConfirm')"
					>执行排班计划</el-button
				>
			</template>
		</jy-query>
		<!-- <time-calendar ref="timeCalendar" @choose="choose" @changeMonth="changeMonth"></time-calendar> -->
		<!-- @dragItem="dragItem" -->
		<time-table :list="list" :operationList="operationList" :isInit="isInit" :isShowIcon="false"></time-table>
		<add-shift ref="npm" @updateList="update"></add-shift>
		<add-plan v-if="addPlanDialog" v-model="addPlanDialog" :routeList="routeList" @reload="oncheck"></add-plan>
		<do-plan v-if="doPlanDialog" v-model="doPlanDialog" :exitRouteList="exitRouteList"></do-plan>
	</div>
</template>
<script>
import timeTable from "@/components/pages/admin/common/timeTable/timeTable";
// import timeCalendar from "@/components/pages/admin/common/timeTable/timeCalendar";
// import addShift from "@/components/pages/admin/scheduling/plannedShift/addShift";
import addShift from "./addShift.vue";
import addPlan from "./addPlan.vue";
import doPlan from "./doPlan.vue";
import { btnMixins } from "@/common/js/button.mixin";
export default {
	data() {
		return {
			schemeId: "",
			formData: {
				routeId: "",
				vehicleNo: "",
				driverNa: ""
			},
			routeList: [],
			list: [],
			operationList: [],
			isInit: true,
			btnMenuId: "scheduleVehicleSchedulSmartSchedul",
			addPlanDialog: false,
			doPlanDialog: false
		};
	},
	mixins: [btnMixins],
	components: {
		timeTable,
		// timeCalendar,
		addShift,
		addPlan,
		doPlan
	},
	async created() {
		this.seOperation();
		await this.getRouteList();
		this.getList();
	},
	methods: {
		getExitRouteList() {
			let url = "/crrc-palm-bus/intelligentScheduling/getPlanRouteInfo";
			this.$http.post(url, {}, { baseType: "base" }).then(res => {
				this.exitRouteList = res || [];
				this.doPlanDialog = true;
			});
		},
		seOperation() {
			this.operationList = [
				{
					name: "编辑",
					fun: item => {
						this.addUdate(item);
					},
					isShow: () => {
						return this.btnexist("scheduleVehicleSchedulSmartSchedulEdit");
					}
				},
				{
					name: "复制",
					fun: item => {
						this.copy(item);
					},
					isShow: () => {
						return this.btnexist("scheduleVehicleSchedulSmartSchedulCopy");
					}
				},
				{
					name: "删除",
					fun: item => {
						this.del(item);
					},
					isShow: () => {
						return this.btnexist("scheduleVehicleSchedulSmartSchedulDelete");
					}
				}
			];
		},
		async getRouteList() {
			let url = "/route/info/queryRouteInfoByOrgIds";
			await this.$http.post(url).then(({ detail }) => {
				this.routeList = detail;
				if (this.routeList.length) {
					this.formData.routeId = this.routeList[0].routeId;
				}
			});
		},
		getList() {
			let option = {
				...this.formData
			};
			let url = "/crrc-palm-bus/intelligentScheduling/queryPlanList";
			this.$http.post(url, option, { baseType: "base" }).then(res => {
				this.list = res || [];
			});
		},
		// 查询表格
		oncheck() {
			this.isInit = true;
			this.getList();
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			if (this.routeList.length) {
				this.formData.routeId = this.routeList[0].routeId;
			}
			this.oncheck();
		},

		addUdate(option) {
			this.$refs.npm.init(option, "update");
		},
		update(id) {
			if (id) {
				this.schemeId = id;
			}
			this.isInit = false;
			this.getList();
		},
		dragItem(e) {
			this.isInit = false;
			let url = "/waybillPlan/update";
			let errOption = {
				err: res => {
					console.log(res);
					this.$message({
						message: res.error,
						type: "error"
					});
					this.getList();
				}
			};
			this.$http.post(url, e, errOption).then(res => {
				this.$message({
					message: res.msg,
					type: "success"
				});
				this.getList();
			});
		},
		copy(item) {
			this.isInit = false;
			const url = "/crrc-palm-bus/intelligentScheduling/copyPlan";
			let option = {
				planId: item.waybillId
			};
			this.$http.post(url, option, { baseType: "base" }).then(() => {
				this.getList();
			});
		},
		del(item) {
			this.isInit = false;
			this.$confirm("是否删除该班次?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				const url = "/crrc-palm-bus/intelligentScheduling/deletePlan";
				const option = {
					planId: item.waybillId
				};
				this.$http.post(url, option, { baseType: "base" }).then(() => {
					this.getList();
				});
			});
		},
		delAllHandle() {
			this.isInit = false;
			this.$confirm("是否删除本日所有的排班计划?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				const url = "/crrc-palm-bus/intelligentScheduling/batchDeletePlan";
				const params = { routeId: this.formData.routeId };
				this.$http.post(url, params, { baseType: "base" }).then(() => {
					this.$message({
						message: "删除成功",
						type: "success"
					});
					this.getList();
				});
			});
		},
		addPlanHandle() {
			this.addPlanDialog = true;
		},
		async doPlanHandle() {
			await this.getExitRouteList();
			// this.doPlanDialog = true;
		}
	}
};
</script>
<style lang="scss" scoped>
.smart-sh {
	padding-bottom: 50px;
}
</style>
